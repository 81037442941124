.nav-links {
  margin-right: 50px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  color: black !important;
}



.active-nav-link {
  font-weight: bold;
  border-bottom: 4px solid #0FF851;  /* Increase the thickness and make the border longer */
  /* Add any other styles for the active link */
}

/* Add this CSS to your stylesheet */
/* carousel.css */
.slick-prev:before, .slick-next:before {
  color: black !important; /* Change arrow color */
  font-size: 35px !important; /* Change arrow size */
}

.slick-prev {
  left: 20px !important; /* Adjust position from the left */
}

.slick-next {
  right: 20px !important; /* Adjust position from the right */
}

/* .active-nav-link {
    position: relative;
  }
  
  .active-nav-link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: green;
  } */


.custom-input {
  position: relative;
}

.publisher {
  position: absolute;
  top: 50%;
  right: 30px;
  /* Adjust position as needed */
  font-weight: bold;
  transform: translateY(-50%);
  pointer-events: none;
  /* Prevents clicks on the "Publisher" text */
  color: black
    /* Adjust color as needed */
}



/* GamesReview.css */

.game-item-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.game-item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.game-item-exit {
  opacity: 1;
  transform: translateY(0);
}
.game-item-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}
